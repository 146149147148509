<template>
  <div>
    <v-btn @click="popDetail(item)"
                small
                color="primary"
                dark
              >
                Detail
        </v-btn>
        <v-dialog
              :value="this.dialog"
              max-width="80%"
              persistent
              transition="dialog-bottom-transition"
              style="z-index: 9999 !important"
            >
              <v-card>
                <!-- <v-toolbar>
                  <v-btn icon @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar> -->

                <div class="modal-header">
                  <h5 class="modal-title" id="formModal">
                    Details
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    @click="onCloseDialog()"
                  >
                    <span @click="this.dialog = false" aria-hidden="true">×</span>
                  </button>
                </div>
                <v-card-text>
                  <br />
                  <div class="row">
                    <div class="col-12 col-md-12 col-sm-12">
                      <pre>{{ newJson }}</pre>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-dialog>
  </div>
</template>

<script>

export default {
  props: ['darkmode', "item", "params", "dataDelete", "userPermission"],
  data() {
    return {
      dialog: false,
      newJson: {}
    };
  },
  methods: {
    onCloseDialog(){
      this.dialog = false;
    },
    popDetail(item){
      this.dialog = true;
      let newUpdate = this.getDiff(JSON.parse(item.old_json),JSON.parse(item.new_json));
      this.newJson = newUpdate;
    },
    getDiff(a, b){
        var diff = (this.isArray(a) ? [] : {});
        this.recursiveDiff(a, b, diff);
        return diff;
    },
    recursiveDiff(a, b, node){
       // var checked = [];

        for(var prop in a){
            if(typeof b[prop] == 'undefined'){
                this.addNode(prop, '[[removed]]', node);
            }
            else if(JSON.stringify(a[prop]) != JSON.stringify(b[prop])){
                // if value
                if(typeof b[prop] != 'object' || b[prop] == null){
                    this.addNode(prop, b[prop], node);
                }
                else {
                    // if array
                    if(this.isArray(b[prop])){
                      this.addNode(prop, [], node);
                      this.recursiveDiff(a[prop], b[prop], node[prop]);
                    }
                    // if object
                    else {
                        this.addNode(prop, {}, node);
                        this.recursiveDiff(a[prop], b[prop], node[prop]);
                    }
                }
            }
        }
    },
    addNode(prop, value, parent){
            parent[prop] = value;
    }

    ,isArray(obj){
        return (Object.prototype.toString.call(obj) === '[object Array]');
    }
  },

};
</script>

<style lang="scss" scoped>
</style>
